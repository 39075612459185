// Usage: anytime an element with the video ad class appears on the page, this script
// will detect it and initialize a video ad.
//
// When the element is no longer visible or is removed from the DOM, it should apply styling
// to make the ad appear in a fixed position, while also appending it to document body.
//
// When the element is visible or re-added into the DOM, the ad should be appended to
// element and fill the size of the container.
//
// All of the business logic for video ads should be self-contained in here.

import EventEmitter from "event-lite";

import { IS_DEV, IS_TESTING } from "@/__main__/constants.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_TRACK_VIDEO_PROVIDER } from "@/feature-ads/constants/events.mjs";
import type { VideoProviderKey } from "@/feature-ads-video-new/constants.mjs";
import { VIDEO_AD_CONTAINER_ID } from "@/feature-ads-video-new/constants.mjs";
import {
  chooseVideoProvider,
  getMemoryUsage,
} from "@/feature-ads-video-new/util.mjs";
import { VIDEO_PROVIDER_INIT } from "@/feature-ads-video-new/video-provider-helper.mjs";
import { devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { safeAppend } from "@/util/helpers.mjs";
import nextFrame from "@/util/next-frame.mjs";

const observer =
  typeof MutationObserver !== "undefined"
    ? new MutationObserver(observerCallback)
    : null;

const activeRef = {
  active: null as HTMLElement | null,
};

let currentProvider: VideoProviderKey;

export const events = new EventEmitter();

export const setupVideoAd = async () => {
  if (!globals.document) return;
  await initialRegistration();
  if (!observer) return;
  observer.observe(globals.document.body, {
    subtree: true,
    childList: true,
    // It is not required to listen to attributes since we will assume that
    // once an ad element is present, it does not change after initialization.
  });
  devLog("[video-ads] observer.observe");
};

export function teardownVideoAd() {
  VIDEO_PROVIDER_INIT[currentProvider].destroy();
  // remove ads node
  if (activeRef.active) {
    activeRef.active.remove();
    activeRef.active = null
  }
  
  if (!observer) return;
  observer.disconnect();
  devLog("[video-ads] observer.disconnect");
}

const initialRegistration = async () => {
  const provider = await chooseVideoProvider();
  if (!provider) return;
  currentProvider = provider;
  devLog("[video-ads] Using Provider", currentProvider);
  // Prepare the script for the ad, but the script has not yet been rendered on the page
  activeRef.active =
    VIDEO_PROVIDER_INIT[currentProvider].initScriptAndCreateContainer();
  setDevPlacement(activeRef.active);
  // render script
  appendAdsScriptContainerToDocument();
};

// Render the script into VIDEO_AD_CONTAINER_ID in the page
const appendAdsScriptContainerToDocument = async () => {
  const adContainer = globals.document.getElementById(VIDEO_AD_CONTAINER_ID);
  // Make sure VIDEO_AD_CONTAINER_ID is empty before inserting the ad script to avoid multiple insertions
  if (adContainer && activeRef.active && !adContainer.hasChildNodes()) {
    safeAppend(adContainer, activeRef.active);
    VIDEO_PROVIDER_INIT[currentProvider].setScriptCustomParams();
    // Only when the script is rendered into the page does it mean that we have loaded the ad.
    const ram = await getMemoryUsage();
    eventBus.emit(EVENT_TRACK_VIDEO_PROVIDER, {
      provider: currentProvider,
      ram,
    });
  }
};

function observerCallback(mutations) {
  for (const { addedNodes } of mutations) {
    for (const addedNode of addedNodes) {
      if (addedNode.nodeType !== Node.ELEMENT_NODE) continue;
      const node = globals.document.getElementById(VIDEO_AD_CONTAINER_ID);
      if (node && activeRef.active) {
        appendAdsScriptContainerToDocument();
        if (IS_TESTING)
          activeRef.active.dataset.testid = node.dataset.pageMacro;
      }
    }
  }
}

// dev helpers, helps to see with no real ads
function setDevPlacement(placement) {
  if (IS_DEV) {
    placement.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    placement.style.border = "1px solid white";
    placement.style.transition = "border 0.5s";
    nextFrame(
      // indicates refreshes
      () => (placement.style.border = "1px solid transparent"),
    );
  }

  return placement;
}
