import { IS_APP } from "@/__main__/constants.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_TRACK_AD_VIDEO_VIEW } from "@/feature-ads/constants/events.mjs";
import type { AdsConfig } from "@/feature-ads/data-model-ads-static.mjs";
import AdsStatic from "@/feature-ads/data-model-ads-static.mjs";
import fetchRemoteAdConfig from "@/feature-ads/fetch-remote-ad-config.mjs";
import { promiseTimeout } from "@/feature-ads/util/promise-timeout.mjs";
import type {VideoProviderKey} from "@/feature-ads-video-new/constants.mjs";
import {
  VIDEO_PROVIDER
} from "@/feature-ads-video-new/constants.mjs";
import { devError, devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import hasQueryString from "@/util/has-query-string.mjs";
import rangeBucket from "@/util/range-bucket.mjs";
import { fetchCountryCode } from "@/util/use-country-code.mjs";

export const getMemoryUsage = async () => {
  let ram = 0;
  if (IS_APP) {
    const heapStats = await globals.__BLITZ__.process.getHeapStatistics();
    ram = heapStats.usedHeapSize * 1024;
    // @ts-ignore
  } else if (globals.performance.memory) {
    // @ts-ignore
    ram = globals.performance.memory.usedJSHeapSize;
  }
  return ram;
};

export async function chooseVideoProvider() {
  if (hasQueryString("__BLITZ_EXCO__")) {
    devLog("[video-ads] Forcing Ex.co");
    return VIDEO_PROVIDER.exco;
  }
  if (hasQueryString("__BLITZ_VIDAZOO__")) {
    devLog("[video-ads] Forcing Vidazoo");
    return VIDEO_PROVIDER.vidazoo;
  }
  if (hasQueryString("__BLITZ_ADITUDE__")) {
    devLog("[video-ads] Forcing Aditude");
    return VIDEO_PROVIDER.aditude;
  }
  let adConfig: AdsConfig, countryCode: string | undefined;
  try {
    [adConfig, countryCode] = await promiseTimeout(
      Promise.all([
        fetchRemoteAdConfig(),
        fetchCountryCode({ skipSafetyCheck: true }),
      ]),
      3000,
      "chooseVideoProvider",
    );
  } catch (error) {
    devError("[video-ads]", error);
    adConfig = AdsStatic.default;
  }
  devLog("[video-ads] country code", countryCode);
  // let's check videoEnabled here, so we don't need to initialize the ads' script
  if (!adConfig.videoEnabled) return "";

  const providers = adConfig.videoProviderExperiments.providers;
  // transform the provider list into a range bucket
  const rangeBucketProviders: [number, string][] = providers.map((p) => [
    p.threshold,
    p.provider,
  ]);
  // get random provider based on reached threshold
  const providerKey = rangeBucket(rangeBucketProviders)[Math.random()];
  return VIDEO_PROVIDER[providerKey];
}

export async function handleImpressionEvent(provider: VideoProviderKey) {
  const ram = await getMemoryUsage();
  eventBus.emit(EVENT_TRACK_AD_VIDEO_VIEW, {
    provider: provider,
    ram,
  });
}
