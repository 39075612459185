import featureFlags, { allowRead } from "@/__main__/feature-flags.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import appRefs from "@/app/refs.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import AdVideoContainer from "@/feature-ads-video-new/AdVideo.jsx";
import adVideoRefs from "@/feature-ads-video-new/AdVideo.refs.jsx";
import {
  setupVideoAd,
  teardownVideoAd,
} from "@/feature-ads-video-new/global-video-ad.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  adVideoRefs,
  adsRefs,
});

let has_setup = false;

const enableVideoAds = () => {
  if (has_setup) return;
  has_setup = true;
  original.set({
    adsRefs: {
      showAdsVideo: true,
    },
    adVideoRefs: {
      AdVideoContainer,
    },
  });
  setupVideoAd();
};

export function setup() {
  appRefs.fetchedFeatureFlags.then(() => {
    allowRead();
    if (featureFlags.ads === true && featureFlags.adsVideoNew) enableVideoAds();
    eventBus.on("adsStatusChange", (enable) => {
      if (enable) {
        enableVideoAds();
      } else {
        setFeatureFlag("adsVideoNew", false);
      }
    });
  });
}

export function teardown() {
  if (!has_setup) return;
  has_setup = false;
  original.restore();
  teardownVideoAd();
}
